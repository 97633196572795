import { create } from "zustand";

import { api } from "@/shared/constants";
import { formatDateShort, formatNumberWithSpaces } from "@/shared/utils/utils";

export const useSessionPageStore = create((set, get) => ({
  allowedRooms: null,
  accounts: [{ value: null, label: "All" }],
  sessions: [],
  loading: false,
  currentPage: 0,
  totalPages: 1,
  searchParams: {
    dateStarted: new Date(),
    dateEnded: new Date(),
  },
  getAccounts: () => get().accounts,
  getAllowedRooms: async (partId) => {
    set({ loading: true });
    try {
      set({ allowedRooms: null, roomTypes: null });
      const response = await api.availableRoomsAndGameTypesReq({
        partId: partId,
      });
      const parsedData = response?.data;
      set({
        allowedRooms: {
          rooms: parsedData.rooms,
          roomTypes: parsedData.gameTypes,
        },
      });
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      set({ loading: false });
    }
  },
  fetchAccounts: async (partId) => {
    set({ loading: true });
    try {
      set({ accounts: [{ value: null, label: "All" }] });
      const response = await api.accountsReqN({
        partId: partId,
      });
      const parsedData = response?.data?.accounts;
      const accounts = parsedData.map((item) => ({
        value: item.account.deviceId,
        label: formatNumberWithSpaces(item.account.deviceId),
      }));
      set({
        accounts: [{ value: null, label: "All" }, ...accounts],
      });
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      set({ loading: false });
    }
  },
  fetchSessions: async ({ page = 0 } = {}) => {
    // fixme to be removed
    const {
      partIds,
      deviceId,
      room,
      gameType,
      dateStarted,
      dateEnded,
      onlyActive,
      showEmpty,
    } = get()?.searchParams || {};

    if (!partIds) {
      return;
    }

    set({ loading: true });

    try {
      const response = await api.sessionsReqN({
        page,
        partIds: [partIds],
        deviceId,
        room,
        gameType,
        dateStarted: formatDateShort(dateStarted),
        dateEnded: formatDateShort(dateEnded),
        onlyActive,
        showEmpty,
      });

      const parsedData = response?.data?.sessions;
      const totalPages = response?.data?.totalPages;

      set((state) => ({
        sessions:
          page === 0
            ? parsedData.map((item) => ({
                session: item,
              }))
            : state.sessions.concat(
                parsedData.map((item) => ({
                  session: item,
                }))
              ),
        totalPages,
        currentPage: page,
      }));
      return response;
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      set({ loading: false });
    }
  },
  setSearchParams: async (_searchParams = {}) => {
    // fixme to be removed
    set(({ searchParams, ...state }) => {
      return {
        ...state,
        searchParams: {
          ...searchParams,
          ..._searchParams,
        },
      };
    });
  },
  setSearchParam: async (key, value) => {
    // fixme to be removed
    if (typeof key === "string") {
      set(({ searchParams } = {}) => ({
        searchParams: {
          ...searchParams,
          [key]: value,
        },
      }));
    }
  },
}));

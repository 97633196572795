import { css } from "@emotion/react";
import React, { HtmlHTMLAttributes, useMemo, useState } from "react";

export function PartnerActionLink({
  disabled = false,
  children,
  onClick = async () => {
    /**/
  },
  ...props
}: HtmlHTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const label = useMemo(() => (isLoading ? "" : children), [children, isLoading]);
  return (
    <div
      className={`p-btn grayBg ${isLoading && "loading"}`}
      css={css`
        &&& {
          opacity: ${disabled || isLoading ? 0.5 : 1};
        }
      `}
      onClick={async (event) => {
        if (disabled || isLoading) {
          return;
        }
        setIsLoading(true);
        try {
          await onClick(event);
        } catch (e) {
          //
        } finally {
          setIsLoading(false);
        }
      }}
      {...props}
    >
      {label}
    </div>
  );
}

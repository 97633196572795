import React from "react";

import { ReactComponent as SvgClose } from "@/shared/images/close.svg";
import { ReactComponent as SvgDelete } from "@/shared/images/delete.svg";

const DeleteModal = ({ closeModal, handleDelete }) => {
  const onDelete = () => {
    handleDelete();
    closeModal();
  };

  return (
    <div className="modal-inner-mini-q">
      <div className="window_close" onClick={closeModal}>
        <SvgClose />
      </div>
      <div className="modal-title-q">You want to delete this account?</div>
      <div className="modal-icon-q">
        <SvgDelete />
      </div>
      <div onClick={onDelete} className="p-btn">
        Delete
      </div>
    </div>
  );
};

export default DeleteModal;

import { css } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { usePartnerFuelTransfer } from "@/entities/partner/api/use-partner-fuel-transfer";
import { usePartnersRetailersQueryKey } from "@/entities/partner/model/use-partners-retailers";
import { useSessionStore } from "@/entities/user/model/session-store";
import { ReactComponent as SvgClose } from "@/shared/images/close.svg";
import { useHeaderStore } from "@/store/HeaderStore";

export const TopUpDialog = ({
  closeModal,
  selectedPartId,
  partnerBalance,
  selectedPartnerName,
}) => {
  const { t } = useTranslation("partners");

  const currentUser = useSessionStore().currentUser;
  const headerStore = useHeaderStore();
  const myBalance = headerStore.balance;

  const queryClient = useQueryClient();

  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const isSubmitDisabled = useMemo(
    () => !Number(inputValue) || Number(inputValue) > myBalance || isLoading,
    [inputValue, isLoading, myBalance]
  );

  const handleInputChange = useCallback((event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setInputValue(value);
    }
  }, []);

  const partnerFuelTransfer = usePartnerFuelTransfer();

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (isSubmitDisabled) {
        return;
      }
      try {
        setIsLoading(true);
        await partnerFuelTransfer({
          srcPartId: currentUser,
          dstPartId: selectedPartId,
          fuelQty: Number(inputValue),
        });
        queryClient?.invalidateQueries({
          queryKey: [usePartnersRetailersQueryKey],
        });
        headerStore.fetchBalance();
        closeModal();
      } catch (error) {
        //
      } finally {
        setIsLoading(false);
      }
    },
    [
      closeModal,
      currentUser,
      headerStore,
      inputValue,
      isSubmitDisabled,
      partnerFuelTransfer,
      queryClient,
      selectedPartId,
    ]
  );

  return (
    <div className="modal-fuel">
      <div className="m-fuel-title">
        {t("topUp")}
        <div className="window_close" onClick={closeModal}>
          <SvgClose />
        </div>
      </div>
      <div className="m-fuel-partner">{selectedPartnerName}</div>
      <div className="my-balance">
        <span>{t("myBalance")}</span>
        <span>{myBalance}</span>
      </div>
      <div className="partner-balance">
        <span>{t("partnerBalance")}</span>
        <span>{partnerBalance}</span>
      </div>
      <div className="m-fuel-input">
        <input
          disabled={isLoading}
          type="text"
          className="st-in"
          value={inputValue}
          placeholder={t("fuelAmount")}
          onChange={handleInputChange}
          css={css`
            &&& {
              opacity: ${isLoading ? 0.5 : 1};
            }
          `}
        />
        <div
          className="fuel-go"
          onClick={handleFormSubmit}
          css={css`
            &&& {
              opacity: ${isSubmitDisabled ? 0.5 : 1};
            }
          `}
        >
          {t("topUp")}
        </div>
      </div>
    </div>
  );
};

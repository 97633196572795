import React from "react";

import { useSessionPageStore } from "@/store/session-page-store";

export function SessionsLoadMoreButton({
  scrollToBottom,
  ...props
}: React.ComponentProps<"button"> & {
  scrollToBottom?: () => void;
}) {
  const sessionPageStore = useSessionPageStore();
  return (
    <button
      onClick={async () => {
        if (sessionPageStore?.currentPage < sessionPageStore?.totalPages - 1) {
          await sessionPageStore?.fetchSessions({
            page: sessionPageStore?.currentPage + 1,
          });
          setTimeout(() => {
            if (typeof scrollToBottom === "function") {
              scrollToBottom();
            }
          }, 5);
        }
      }}
      className={`p-btn ${sessionPageStore.loading && "loading"} grayBg more-btn`}
      {...props}
    >
      {sessionPageStore.loading ? "" : "Load more"}
    </button>
  );
}

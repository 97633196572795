import { useMemo } from "react";

import { useCurrentPartnerRooms } from "@/entities/partner/model/use-current-partner-rooms";

import { useBotModeSelectOptions } from "./options/use-bot-mode-select-options";
import { useRoomSelectOptions } from "./options/use-room-select-options";
import { useRoomTypeSelectOptions } from "./options/use-room-type-select-options";

export function useConfigFiltersSelectOptions({
  selectedRoom,
  selectedRoomType,
} = {}) {
  const allowedRooms = useCurrentPartnerRooms();
  const roomSelectOptions = useRoomSelectOptions(allowedRooms?.data);
  const roomTypeSelectOptions = useRoomTypeSelectOptions(
    allowedRooms?.data,
    selectedRoom
  );
  const botModeSelectOptions = useBotModeSelectOptions(
    allowedRooms?.data,
    selectedRoom,
    selectedRoomType
  );
  return useMemo(
    () => ({
      isFetching: allowedRooms?.isFetching,
      roomSelectOptions: roomSelectOptions || [],
      roomTypeSelectOptions: roomTypeSelectOptions || [],
      botModeSelectOptions: botModeSelectOptions || [],
    }),
    [
      botModeSelectOptions,
      allowedRooms?.isFetching,
      roomSelectOptions,
      roomTypeSelectOptions,
    ]
  );
}

import { useMemo } from "react";

import { useCurrentPartnerRooms } from "../../partner/model/use-current-partner-rooms";
import { useConfigLastFilters } from "./use-config-last-filters";
import { useConfigRouteFilters } from "./use-config-route-filters";

export function useConfigInitialFilters() {
  const allowedRooms = useCurrentPartnerRooms();

  const routeFilters = useConfigRouteFilters();
  const hasRouteFilters = useMemo(
    () => !!Object.keys(routeFilters)?.length,
    [routeFilters]
  );

  const lastFilters = useConfigLastFilters();
  const {
    lastRoom,
    lastGameType: lastRoomType,
    lastWorkingMode,
  } = lastFilters?.data || {};
  // (0-MANUAL, 1-AUTO, 2-VIP)
  const lastBotMode =
    lastWorkingMode && { MANUAL: "0", AUTO: "1", VIP: "2" }?.[lastWorkingMode];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allowedFirstRoom = Object.keys(allowedRooms?.data || {})?.[0];
  const allowedRouteRoom = Object.keys(allowedRooms?.data || {})?.find(
    (room) => room === routeFilters?.selectedRoom
  );
  const allowedLastRoom = Object.keys(allowedRooms?.data || {})?.find(
    (room) => room === lastRoom
  );

  const selectedRoom =
    (hasRouteFilters
      ? allowedRouteRoom
      : allowedLastRoom) /*|| allowedFirstRoom*/ || "";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allowedFirstRoomType = Object.keys(
    allowedRooms?.data?.[selectedRoom] || {}
  ).filter((item) => item !== "icon")?.[0];
  const allowedRouteRoomType = Object.keys(allowedRooms?.data?.[selectedRoom] || {})
    .filter((item) => item !== "icon")
    ?.find((roomType) => roomType === routeFilters?.selectedRoomType);
  const allowedLastRoomType = Object.keys(allowedRooms?.data?.[selectedRoom] || {})
    .filter((item) => item !== "icon")
    ?.find((roomType) => roomType === lastRoomType);

  const selectedRoomType =
    (hasRouteFilters ? allowedRouteRoomType : allowedLastRoomType) /*||
    allowedFirstRoomType*/ || "";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allowedFirstBotMode = Object.keys(
    allowedRooms?.data?.[selectedRoom]?.[selectedRoomType] || {}
  )?.[0];
  const allowedRouteBotMode = Object.keys(
    allowedRooms?.data?.[selectedRoom]?.[selectedRoomType] || {}
  )?.find((botMode) => botMode === routeFilters?.selectedBotMode);
  const allowedLastBotMode = Object.keys(
    allowedRooms?.data?.[selectedRoom]?.[selectedRoomType] || {}
  )?.find((botMode) => botMode === lastBotMode);

  const selectedBotMode =
    (hasRouteFilters ? allowedRouteBotMode : allowedLastBotMode) /*||
    allowedFirstBotMode*/ || "";

  return useMemo(
    () => ({
      isLoading: lastFilters?.isLoading,
      isFetching: lastFilters?.isFetching,
      selectedRoom,
      selectedRoomType,
      selectedBotMode,
    }),
    [
      lastFilters?.isFetching,
      lastFilters?.isLoading,
      selectedBotMode,
      selectedRoom,
      selectedRoomType,
    ]
  );
}

const apiTranslations = {
  en: {
    SOMETHING_WENT_WRONG: "SOMETHING WENT WRONG",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS:
      "Some partners haven't deleted accounts",
    OPERATION_LOCKED: "Operation is locked",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_SUBPARTNER:
      "Partner is not allowed to create sub-partners",
    NOT_ALLOWED_PARTNER: "Partner is not allowed",
    NOT_ALLOWED_PARTNERS_OR_SOME_DOES_NOT_EXIST:
      "Some partners are not allowed or don't exist",
    PARTNER_DOES_NOT_EXIST: "Partner does not exist",
    INVALID_SRCPARTNER_DSTPARTNER: "Invalid source or destination partner",
    INVALID_FUEL_QUANTITY: "Invalid fuel amount",
    INSUFFICIENT_FUEL_BALANCE: "Insufficient fuel balance",
    INVALID_PARTNER: "Invalid partner",
    NOT_ALLOWED_ACCOUNTS: "Not allowed accounts",
    SOME_OF_ACCOUNTS_DO_NOT_EXIST: "Some accounts do not exist",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_RETAILERS:
      "Partner is not allowed to create retailers",
    RETAILER_IS_NOT_ALLOWED_TO_LOGIN_TO_ADMIN_PANEL:
      "Retailer is not allowed to log into the admin panel",
    TIME_FORMAT_ERROR: "Time format error",
    ERROR_OF_DELETING_TABLES: "Error deleting tables",
    CHECK_ROOMS_ALLOWED_FOR_PARTNER: "Check if rooms are allowed for the partner",
    DATE_START_INVALID: "Invalid start date",
    DATE_END_INVALID: "Invalid end date",
    MAPPING_FOR_PARTNER_AND_DEVICEID_WAS_NOT_FOUND:
      "Mapping for partner and device ID not found",
    API_CONVERT_ERROR: "API conversion error",
    NO_INVOICE_FOUND_FOR_TOKEN: "No invoice found for the token",
    API_LOGS_ERROR: "API logs error",
    INVOICE_IS_NOT_FOUND: "Invoice is not found",
    INVOICE_AND_LOGGED_PARTNERS_ARE_NOT_MATCHING:
      "Invoice and logged partners don't match",
    PLEASE_CHECK_AGAIN: "Please check again",
    MINIMUM_TRANSACTION_AMOUNT: "Minimum transaction amount",
    WALLETS_ARE_NOT_EQUAL_CONTACT_ADMIN: "Wallets are not equal, contact admin",
    NOT_ALLOWED_TO_DEPOSIT: "Not allowed to deposit",
    API_QRCODE_ERROR: "API QR code error",
    API_CREATE_ERROR: "API creation error",
    REACHED_DAILY_INVOICES_LIMIT: "Daily invoice limit reached",
    NO_SUCH_CURRENCY: "No such currency",
    INVALID_AMOUNT: "Invalid amount",
    PARTNER_AND_ACCOUNT_ARE_EMPTY: "Partner and account are empty",
    ACCOUNT_DOES_NOT_EXIST: "Account does not exist",
    INVALID_API_KEY: "Invalid API key",
    PARTNER_IS_NOT_SUBAGENT: "Partner is not sub-agent",
    UNKNOWN_COMMAND: "Unknown command",
    CONFID_IS_NOT_ALLOWED_BY_ADMIN: "Confid is not allowed by admin",
    ROOM_GAMETYPE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "Room game type not allowed for partner",
    CONFID_WAS_NOT_FOUND: "Confid was not found",
    REQUESTED_PARTNER_SHOULD_MATCH_PARTNER_OPENED_IN_THE_TOP:
      "Partner should match the one opened at the top",
    ROOM_GAMETYPE_MODE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "Room game type or mode not allowed for partner",
    LIMIT_ACCOUNT_REACHED: "Account limit reached",
    MULTILOGIN_DETECTED: "Multi-login detected",
    INVALID_PARTNER_AUTHKEY_OR_DISABLED: "Invalid partner auth key or disabled",
    INVALID_VALUES: "Invalid values",
    ACCOUNT_DISABLED: "Account disabled",
    CURRENT_PARTNER_IS_NOT_ADMIN: "Current partner is not admin",
    AUTH_REQUIRED: "Authentication required",
    PARTNER_IS_NOT_AGENT: "Partner is not agent",
    WS_ANDROIDID_MULTILOGIN_DETECTED: "This android ID already connected to device",
  },
  zh: {
    SOMETHING_WENT_WRONG: "SOMETHING WENT WRONG",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS: "部分合作伙伴尚未删除账户",
    OPERATION_LOCKED: "操作已锁定",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_SUBPARTNER: "合作伙伴不允许创建子合作伙伴",
    NOT_ALLOWED_PARTNER: "不允许的合作伙伴",
    NOT_ALLOWED_PARTNERS_OR_SOME_DOES_NOT_EXIST:
      "不允许的合作伙伴或部分合作伙伴不存在",
    PARTNER_DOES_NOT_EXIST: "合作伙伴不存在",
    INVALID_SRCPARTNER_DSTPARTNER: "无效的源合作伙伴和目标合作伙伴",
    INVALID_FUEL_QUANTITY: "无效的燃料数量",
    INSUFFICIENT_FUEL_BALANCE: "燃料余额不足",
    INVALID_PARTNER: "无效的合作伙伴",
    NOT_ALLOWED_ACCOUNTS: "不允许的账户",
    SOME_OF_ACCOUNTS_DO_NOT_EXIST: "部分账户不存在",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_RETAILERS: "合作伙伴不允许创建零售商",
    RETAILER_IS_NOT_ALLOWED_TO_LOGIN_TO_ADMIN_PANEL: "零售商不允许登录管理面板",
    TIME_FORMAT_ERROR: "时间格式错误",
    ERROR_OF_DELETING_TABLES: "删除表格时出错",
    CHECK_ROOMS_ALLOWED_FOR_PARTNER: "检查合作伙伴是否允许平台",
    DATE_START_INVALID: "开始日期无效",
    DATE_END_INVALID: "结束日期无效",
    MAPPING_FOR_PARTNER_AND_DEVICEID_WAS_NOT_FOUND: "未找到合作伙伴和设备ID的映射",
    API_CONVERT_ERROR: "API转换错误",
    NO_INVOICE_FOUND_FOR_TOKEN: "未找到对应的发票",
    API_LOGS_ERROR: "API日志错误",
    INVOICE_IS_NOT_FOUND: "未找到发票",
    INVOICE_AND_LOGGED_PARTNERS_ARE_NOT_MATCHING: "发票和登录的合作伙伴不匹配",
    PLEASE_CHECK_AGAIN: "请再检查一次",
    MINIMUM_TRANSACTION_AMOUNT: "最低交易金额",
    WALLETS_ARE_NOT_EQUAL_CONTACT_ADMIN: "钱包不相等，请联系管理员",
    NOT_ALLOWED_TO_DEPOSIT: "不允许存款",
    API_QRCODE_ERROR: "API二维码错误",
    API_CREATE_ERROR: "API创建错误",
    REACHED_DAILY_INVOICES_LIMIT: "已达到每日发票限制",
    NO_SUCH_CURRENCY: "没有这种货币",
    INVALID_AMOUNT: "无效金额",
    PARTNER_AND_ACCOUNT_ARE_EMPTY: "合作伙伴和账户均为空",
    ACCOUNT_DOES_NOT_EXIST: "账户不存在",
    INVALID_API_KEY: "无效的API密钥",
    PARTNER_IS_NOT_SUBAGENT: "合作伙伴不是子代理",
    UNKNOWN_COMMAND: "未知命令",
    CONFID_IS_NOT_ALLOWED_BY_ADMIN: "管理员不允许CONFID",
    ROOM_GAMETYPE_ARE_NOT_ALLOWED_FOR_THE_PARTNER: "不允许合作伙伴的平台游戏类型",
    CONFID_WAS_NOT_FOUND: "未找到CONFID",
    REQUESTED_PARTNER_SHOULD_MATCH_PARTNER_OPENED_IN_THE_TOP:
      "请求的合作伙伴应与顶部打开的合作伙伴匹配",
    ROOM_GAMETYPE_MODE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "不允许合作伙伴的平台游戏模式",
    LIMIT_ACCOUNT_REACHED: "已达到账户限制",
    MULTILOGIN_DETECTED: "检测到多重登录",
    INVALID_PARTNER_AUTHKEY_OR_DISABLED: "无效的合作伙伴授权密钥或已禁用",
    INVALID_VALUES: "无效值",
    ACCOUNT_DISABLED: "账户已禁用",
    CURRENT_PARTNER_IS_NOT_ADMIN: "当前合作伙伴不是管理员",
    AUTH_REQUIRED: "需要身份验证",
    PARTNER_IS_NOT_AGENT: "合作伙伴不是代理",
    WS_ANDROIDID_MULTILOGIN_DETECTED: "此安卓设备已连接到设备",
  },
};

export default apiTranslations;

// sort-imports-ignore
import "./App.scss";
import "@/shared/ui/simplebar/simplebar.css";
import "react-toastify/dist/ReactToastify.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { router } from "./router";
import { useSessionStore } from "@/entities/user/model/session-store";
import { ThemeProvider } from "@mui/material";
import { theme as muiTheme } from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const darkModeCookie = Cookies.get("isDarkMode");
  const { theme } = useSessionStore();
  useEffect(() => {
    if (darkModeCookie === "1") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkModeCookie]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={muiTheme}>
        <div className="App">
          <RouterProvider router={router} />
        </div>
      </ThemeProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
        transition={Slide}
        style={{ zIndex: 99999, width: "480px" }}
      />
    </QueryClientProvider>
  );
}

export default App;

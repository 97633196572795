import { diff } from "deep-object-diff";
import { create } from "zustand";

import { api } from "@/shared/constants";

export const useConfigStore = create((set, get) => ({
  config: {},
  userValues: {},
  allowedRooms: null,
  totalPriceAdd: 0,
  applyStatuses: {},

  forceSaveList: [],

  resetAll: () =>
    set({
      config: {},
      userValues: {},
      forceSaveList: [],
      allowedRooms: null,
      applyStatuses: {},
    }),

  addForceSaveItem: (groupId) => {
    const { forceSaveList } = get();
    if (groupId && !forceSaveList.includes(groupId)) {
      set({
        forceSaveList: [...forceSaveList, groupId],
      });
    }
  },

  removeForceSaveItem: (groupId) => {
    const { forceSaveList } = get();
    if (groupId && forceSaveList.includes(groupId)) {
      set({
        forceSaveList: forceSaveList?.filter((id) => id !== groupId),
      });
    }
  },

  getConfigGroup: (groupId) => {
    const { config } = get();
    return [...(config?.[0] || []), ...(config?.[1] || [])]?.filter(
      (item) => item?.confId === groupId || item?.parentConfId === groupId
    );
  },

  getUserGroup: (groupId) => {
    const { getConfigGroup, userValues } = get();
    return (getConfigGroup(groupId) || []).reduce((acc, item) => {
      return {
        ...acc,
        [item?.confId]: userValues?.[item?.confId],
      };
    }, {});
  },

  isGroup(confId) {
    const { config } = get();
    return [...(config?.[0] || []), ...(config?.[1] || [])]?.some(
      (item) => item.parentConfId === confId
    );
  },

  getAllowedRooms: async (partId) => {
    try {
      const response = await api.getAllowedRoomsReq({
        partId: partId,
      });
      const parsedData = response.data;
      set({ allowedRooms: parsedData.allowedRooms });
    } catch (error) {
      console.error("Error: ", error);
    }
  },

  setApplyStatus: (key, isChecked) =>
    set((state) => ({
      applyStatuses: {
        ...state.applyStatuses,
        [key]: isChecked,
      },
    })),

  setConfig: (config = {}) => {
    set({
      config,
    });
  },

  setUserValues: (userValues = {}) => {
    set({
      userValues,
    });
  },

  userFormat: (configs = []) => {
    return configs?.reduce((acc, configItem) => {
      return {
        ...acc,
        [configItem?.confId]:
          typeof configItem?.currentValue === "string" && !!configItem?.currentValue
            ? configItem?.currentValue
            : configItem?.defaultValue,
      };
    }, {});
  },

  saveFormat: (userValues) => {
    return Object.entries(userValues).map(([key, value]) => {
      return {
        confId: Number(key),
        currentValue: value,
      };
    });
  },

  setUserValue: (confId, value) => {
    const {
      config,
      forceSaveList,
      getConfigGroup,
      getUserGroup,
      userFormat,
      isGroup,
      userValues,
    } = get();

    const configItem = [...(config?.[0] || []), ...(config?.[1] || [])]?.find(
      (item) => item?.confId === confId
    );

    if (configItem?.parentConfId || isGroup(confId)) {
      const itemId = isGroup(confId) ? confId : configItem.parentConfId;
      const initialGroup = userFormat(getConfigGroup(itemId));
      const userGroup = getUserGroup(itemId);

      const difference = diff(initialGroup, {
        ...userGroup,
        [itemId]: value,
      });

      if (Object.keys(difference)?.length) {
        if (!forceSaveList.includes(itemId)) {
          set({
            forceSaveList: [...forceSaveList, itemId],
          });
        }
      } else {
        // track only enable (by spec)
        // if (forceSaveList.includes(itemId)) {
        //   set({
        //     forceSaveList: forceSaveList?.filter((groupId) => groupId !== itemId),
        //   });
        // }
      }
    }

    set((state) => ({
      userValues: { ...state.userValues, [confId]: value },
    }));
  },

  resetUserValues: (groupId) => {
    const { config, userValues, userFormat, getConfigGroup } = get();
    if (groupId) {
      set({
        userValues: {
          ...userValues,
          ...userFormat(getConfigGroup(groupId)),
        },
      });
      return;
    }
    set({
      userValues: userFormat([...(config?.[0] || []), ...(config?.[1] || [])]),
      forceSaveList: [],
      applyStatuses: {},
    });
  },
}));

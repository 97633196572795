import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ReactComponent as SvgClose } from "@/shared/images/close.svg";
import { useDepositStore } from "@/store/DepositStore";
import { useHeaderStore } from "@/store/HeaderStore";

const ConfettiContainer = ({ numberOfPieces, setShowConfetti }) => {
  useEffect(() => {
    const stopConfetti = setTimeout(() => {
      setShowConfetti(false);
    }, 7000); // Длительность анимации конфетти

    return () => clearTimeout(stopConfetti);
  }, [setShowConfetti]);

  return ReactDOM.createPortal(
    <div className="confetti-container">
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={numberOfPieces}
        recycle={false}
      />
    </div>,
    document.querySelector(".modal-overlay")
  );
};

const DepositModal = ({ closeModal }) => {
  const { t } = useTranslation("header");
  const [entered, setEntered] = useState(10);
  const [actCalc, setActCalc] = useState(null);
  const [nextCalc, setNextCalc] = useState(null);
  const [paymentContent, setPaymentContent] = useState("initial");
  const [showConfetti, setShowConfetti] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(true);
  const [confettiParticles, setConfettiParticles] = useState(500);
  const [confirmations, setConfirmations] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

  const headerStore = useHeaderStore();
  const depositStore = useDepositStore();
  const invoice = depositStore.invoice;

  useEffect(() => {
    depositStore.initialRequest();
  }, []);

  const sortedCalculation = depositStore.gifts
    .slice()
    .sort((a, b) => a.amount - b.amount);
  let actCalcIndex = -1;

  useEffect(() => {
    for (let i = 0; i < sortedCalculation.length; i++) {
      if (sortedCalculation[i].amount <= Number(entered)) {
        actCalcIndex = i;
      } else {
        break;
      }
    }
    if (actCalcIndex !== -1) {
      setActCalc(sortedCalculation[actCalcIndex]);
      if (actCalcIndex < sortedCalculation.length - 1) {
        setNextCalc(sortedCalculation[actCalcIndex + 1]);
      } else {
        setNextCalc(null);
      }
    } else {
      setActCalc(null);
      setNextCalc(sortedCalculation[0]);
    }
  }, [entered, depositStore.gifts]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setEntered(value);
    }
  };

  const [confettiParams, setConfettiParams] = useState({
    numberOfPieces: { confettiParticles },
    colors: ["#84366E", "#DE1971", "#eee"],
  });

  const handleAddClick = () => {
    if (nextCalc) {
      setEntered(Number(nextCalc.amount));
    } else {
      setConfettiParticles(500);
      setShowConfetti(true);
    }
  };

  useEffect(() => {
    if (isFetching) {
      setTransaction(true);

      const fetchInterval = setInterval(async () => {
        const data = await depositStore.manualCallback(invoice.invId); // замените 'YOUR_INV_ID' на необходимый invId

        if (data && data.status === "SUCCESS" && data.txCompleted) {
          clearInterval(fetchInterval);
          setIsFetching(false);
          setConfirmations(data.confirmations);
          headerStore.fetchBalance().then();
        }
      }, 12000);

      return () => {
        clearInterval(fetchInterval);
      };
    }
  }, [isFetching, depositStore.manualCallback]);

  const handlePayClick = () => {
    setIsInvoiceLoading(true);
    depositStore.createInvoice(entered).then(() => {
      setPaymentContent("next_step");
      setIsInvoiceLoading(false);
    });
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(invoice.walletAddressIn)
      .then(() => {
        toast.success("Address copied successfully!");
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена:", error);
        toast.error("Failed to copy address.");
      });
  };

  const onCloseModal = () => {
    closeModal();
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <div className="modal-deposit">
      <div className="m-deposit-title">
        {t("deposit")}
        <div className="window_close" onClick={onCloseModal}>
          <SvgClose />
        </div>
      </div>
      {paymentContent === "initial" ? (
        <div className="m-deposit-inner">
          <div className="m-deposit-go">
            <div className="m-deposit-input">
              <span>{t("usdtTrc20Amount")}</span>
              <input
                type="text"
                className="st-in"
                value={entered}
                onChange={handleChange}
              />
              {actCalc && (
                <span className="m-deposit-input-gift">
                  gift + {actCalc.percent}%
                </span>
              )}
            </div>
            <div className="m-deposit-fuel">
              <span>{t("fuelAmount")}</span>
              <span className="fuel-amount-number">
                {actCalc
                  ? formatNumber(
                      (Number(entered) *
                        depositStore.usdtToFuelRatio *
                        (100 + actCalc.percent)) /
                        100
                    )
                  : formatNumber(entered * depositStore.usdtToFuelRatio)}
              </span>
            </div>
          </div>
          {nextCalc ? (
            <div className="m-deposit-add" onClick={handleAddClick}>
              {t("add")} <span>{nextCalc.amount - Number(entered)}</span> USDT{" "}
              {t("nextGift")}
              <span>{nextCalc.percent}%</span>
            </div>
          ) : (
            ""
          )}

          {depositStore.gifts.length && nextCalc === null ? (
            <div className="m-deposit-add congrats" onClick={handleAddClick}>
              Congratulations 🎉 You've reached the maximum gift!
            </div>
          ) : (
            ""
          )}

          {/* Здесь выводим содержимое блока в зависимости от состояния paymentContent */}

          <button
            className={`p-btn m-deposit-pay grayBg ${
              isInvoiceLoading ? "updating" : ""
            }`}
            onClick={handlePayClick}
          >
            {isInvoiceLoading ? "" : t("pay")}
          </button>
        </div>
      ) : (
        <div className="m-deposit-pay">
          <div className="deposit-to-pay">
            {t("send")}
            <span> {entered} USDT TRC20</span>
          </div>
          <div onClick={handleCopyClick} className="p-btn">
            {t("copyAddress")}
          </div>
          <div className="address">{invoice.walletAddressIn}</div>
          <div className="deposit-qr-t">{t("scanFromQr")}</div>
          <div>
            {invoice?.qrCode && (
              <img
                className="deposit-qr"
                src={`data:image/png;base64,${invoice.qrCode}`}
                alt="Decoded PNG"
              />
            )}
          </div>
          <div className="deposit-received">
            {transaction ? (
              isFetching ? (
                <>
                  {confirmations ? confirmations : 0} {t("confirmations")}
                </>
              ) : (
                <>{t("transactionReceived")}</>
              )
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <button
            onClick={() => setIsFetching(true)}
            disabled={transaction}
            className="paid-check"
          >
            {t("iPaid")}
          </button>
        </div>
      )}
      {showConfetti && (
        <ConfettiContainer
          numberOfPieces={confettiParticles}
          setShowConfetti={setShowConfetti}
        />
      )}
    </div>
  );
};

export default DepositModal;

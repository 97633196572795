import React from "react";

import { FilterSelect } from "@/shared/ui/filter-select";
import { useSessionPageStore } from "@/store/session-page-store";

export function SessionsAccount({ options, ...props }) {
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();
  const sessionPageStore = useSessionPageStore();
  const accountOptions = sessionPageStore.getAccounts();
  return (
    <FilterSelect
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      options={options || accountOptions}
      defaultValue={accountOptions?.[0]}
      {...props}
    />
  );
}

import React from "react";

import { FilterDatePicker } from "@/shared/ui/filter-date-picker";
import { useSessionPageStore } from "@/store/session-page-store";

export function SessionsDateEnded({ ...props }) {
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();
  return (
    <FilterDatePicker
      // defaultValue={defaults?.dateEnded?.option}
      placeholderText="Date to"
      id="dateEnded"
      name="dateEnded"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      {...props}
    />
  );
}

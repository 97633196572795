import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FuelTransferReq } from "@/api/Api";
import { api } from "@/shared/constants";

export function usePartnerFuelTransfer() {
  const { t } = useTranslation("api");
  return useCallback(
    async (request: FuelTransferReq) => {
      return api?.fuelTransferReq(request)?.then((res) => {
        if (res?.data?.status?.toUpperCase() === "ERROR") {
          toast.error(t(res?.data?.code || "SOMETHING_WENT_WRONG"), {
            delay: 0,
            autoClose: 5000,
            position: "bottom-center",
          });
        }
        return res;
      });
    },
    [t]
  );
}

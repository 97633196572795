import { css } from "@emotion/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DwnlSessionsReq } from "@/api/Api";
import { useDownloadSessionsCsv } from "@/entities/session/use-download-sessions-csv";
import removeEmpty from "@/shared/utils/remove-empty";
import { formatDateShort } from "@/shared/utils/utils";

export function DownloadSessionsCsvButton({
  partIds,
  dateStarted,
  dateEnded,
  deviceId,
  room,
  gameType,
  isLoading = false,
  ...props
}: React.ComponentProps<"button"> &
  DwnlSessionsReq & {
    isLoading?: boolean;
  }) {
  const { t } = useTranslation("sessions");
  const downloadSessionsCsv = useDownloadSessionsCsv();
  return (
    <button
      disabled={isLoading}
      onClick={async () => {
        if (!dateStarted) {
          toast.error(t("specify_date_from"));
          return;
        }
        if (!dateEnded) {
          toast.error(t("specify_date_to"));
          return;
        }
        await downloadSessionsCsv(
          removeEmpty({
            partIds,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dateStarted: formatDateShort(dateStarted)!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dateEnded: formatDateShort(dateEnded)!,
            deviceId,
            room,
            gameType,
          })
        );
      }}
      className="p-btn grayBg"
      css={css`
        &&& {
          opacity: ${isLoading ? 0.5 : 1};
        }
      `}
      {...props}
    >
      {t("downloadCSV")}
    </button>
  );
}

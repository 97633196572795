import identity from "lodash/identity";
import isEmpty from "lodash/isEmpty";
import isObjectLike from "lodash/isObjectLike";
import pickBy from "lodash/pickBy";

export default function removeEmpty(val: any) {
  return isObjectLike(val)
    ? pickBy(val, (i) => {
        return isObjectLike(i) ? !isEmpty(i) : identity(i);
      })
    : val;
}

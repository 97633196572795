import React, { useCallback, useMemo } from "react";

export function FilterCheckBox({
  name,
  children,
  searchParams,
  setSearchParam,
  setSearchParams,
  resetOthers,
  onChange,
  ...props
}) {
  const isChecked = useMemo(() => {
    if (typeof name === "string") {
      return searchParams?.[name];
    }
  }, [name, searchParams]);

  const $onChange = useCallback(
    (event) => {
      if (resetOthers) {
        setSearchParams({
          [name]: event?.target?.checked,
        });
        return;
      }
      setSearchParam(name, event?.target?.checked);
      if (typeof onChange === "function") {
        onChange?.(event);
      }
    },
    [name, onChange, resetOthers, setSearchParam, setSearchParams]
  );

  return (
    <label className="filter-check">
      <input
        type="checkbox"
        checked={!!isChecked}
        onChange={$onChange}
        {...props}
      />
      {children}
    </label>
  );
}

import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import {
  PartnersReqN,
  PartnersRespN,
  RetailersReqN,
  RetailersRespN,
} from "@/api/Api";
import { useSessionStore } from "@/entities/user/model/session-store";
import { api } from "@/shared/constants";

export const usePartnersRetailersQueryKey = `partners-retailers`;

export type PartnersRetailersReqN = PartnersReqN & RetailersReqN;
export type PartnersRetailersRespN = PartnersRespN & RetailersRespN;

export function usePartnersRetailers(
  query: Omit<PartnersRetailersReqN, "partId">,
  queryOptions?: Omit<
    UseQueryOptions<PartnersRetailersRespN["partners" | "retailers"]>,
    "queryKey"
  >
) {
  const session = useSessionStore();
  const isSubagent = session?.role === "SUBAGENT";
  return useQuery({
    queryKey: [
      usePartnersRetailersQueryKey,
      { partId: session?.currentUser, ...query },
    ],
    placeholderData: (prev) => prev,
    queryFn: async () => {
      return isSubagent
        ? api
            ?.retailersReqN({ partId: session?.currentUser, ...query })
            ?.then((response) => response?.data?.retailers)
        : api
            ?.partnersReqN({ partId: session?.currentUser, ...query })
            ?.then((response) => response?.data?.partners);
    },
    enabled: !!session?.currentUser,
    refetchOnWindowFocus: "always",
    ...queryOptions,
  });
}

import { css } from "@emotion/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { ConfigModal } from "@/entities/config/ui/config-modal";
import { usePartnersStore } from "@/entities/partner/model/partners-store";
import { useSessionStore } from "@/entities/user/model/session-store";
import { useUserEventBusEvents } from "@/entities/user/use-user-event-bus-events";
import { ReactComponent as SvgApk } from "@/shared/images/apk.svg";
import { ReactComponent as SvgEnergy } from "@/shared/images/energy.svg";
import { ReactComponent as SvgHistory } from "@/shared/images/history.svg";
import { ReactComponent as SvgOptions } from "@/shared/images/options.svg";
import { ReactComponent as SvgSettings } from "@/shared/images/settings.svg";
import { ReactComponent as SvgUser } from "@/shared/images/user.svg";
import {
  extractApkFilename,
  formatLabelForHeader,
  formatNumberWithSpaces,
  parseTelegramUsername,
} from "@/shared/utils/utils";
import { useHeaderStore } from "@/store/HeaderStore";

import DarkModeSwitch from "./DarkModeSwitch";
import LangSwitch from "./LangSwitch";
import Select from "./Select";
import DeleteModal from "./modal/DeleteModal";
import DepositModal from "./modal/DepositModal";
import GuideModal from "./modal/GuideModal";
import HistoryModal from "./modal/HistoryModal";
import ResetModal from "./modal/ResetModal";
import SettgModal from "./modal/SettgModal";

const Header = ({ itemSelected }) => {
  Modal.setAppElement("#modal-root");

  const location = useLocation();
  const isAccountsRouteActive = location.pathname.toLowerCase() === "/accounts";
  const { t } = useTranslation("header");

  const [selectedModal, setSelectedModal] = useState(null);
  const sessionStore = useSessionStore();
  const headerStore = useHeaderStore();
  const accountsStore = useAccountsStore();
  const partnersStore = usePartnersStore();

  const [selectOptions, setSelectOptions] = useState([
    {
      value: sessionStore.data.partId,
      label: formatNumberWithSpaces(sessionStore.data.partnerName),
      role: sessionStore.role,
    },
  ]);

  const openModal = (modalName) => {
    setSelectedModal(modalName);
  };

  const handleSelect = (option) => {
    sessionStore.setCurrentUser(option.value, option.role);
    headerStore.setPartnerSelection(option.value);
    headerStore.resetBalanceHistory();
    // Сброс выбранных аккаунтов при смене пользователя
    accountsStore.resetSelectedAccounts();
  };

  const handleGenerateAuthKey = () => {
    partnersStore
      .generateAuthKey(sessionStore.data.partId)
      .then((res) => {
        sessionStore.setNewAgentCode(res.newAuthkey);
        navigator.clipboard
          .writeText(res.newAuthkey)
          .then(() => {
            toast.success("Code reset successfully and copied to clipboard!");
          })
          .catch((error) => {
            console.error("Ошибка копирования в буфер обмена:", error);
            toast.success("Code reset successfully! Failed to copy to clipboard.");
          });
        closeModal();
      })
      .catch((error) => {
        console.error("Ошибка сброса кода:", error);
        toast.error("Failed to reset code.");
      });
  };

  useEffect(() => {
    if (sessionStore.subPartners) {
      setSelectOptions(
        [
          {
            value: sessionStore.data.partId,
            label: formatNumberWithSpaces(sessionStore.data.partnerName),
            role: sessionStore.data.role,
          },
        ].concat(
          sessionStore.subPartners.map((item) => ({
            value: item.partId,
            label: formatLabelForHeader(item.name, item.note),
            role: item.role,
          }))
        )
      );
    }
  }, [sessionStore.subPartners]);

  const handleDelete = () => {
    accountsStore
      .deleteSelectedAccounts(accountsStore.getSelectedAccounts())
      .then(() => {
        accountsStore.resetSelectedAccounts();
        accountsStore.fetchAccounts(sessionStore.getCurrentUser());
      });
  };

  const closeModal = () => {
    // resetUserValues();
    setSelectedModal(null);
    if (!isGuideModalOpen) {
      document.body.classList.remove("ReactModal__Body--open");
    }
  };

  const [isItemOptionsOpen, setIsItemOptionsOpen] = useState(false);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const [isGuideModalOpen, setIsGuideModalOpen] = useState(false);

  function openGuideModal() {
    setIsGuideModalOpen(true);
    document.body.classList.add("guide--open");
  }

  function closeGuideModal() {
    setIsGuideModalOpen(false);
    document.body.classList.remove("ReactModal__Body--open", "guide--open");
  }

  const itemOptionsRef = useRef(null);

  const userMenuRef = useRef(null);

  const toggleItemOptions = () => {
    setIsItemOptionsOpen(!isItemOptionsOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      itemOptionsRef.current &&
      !itemOptionsRef.current.contains(event.target) &&
      isItemOptionsOpen
    ) {
      setIsItemOptionsOpen(false);
    }

    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(event.target) &&
      isUserMenuOpen &&
      !isGuideModalOpen
    ) {
      setIsUserMenuOpen(false);
    }
  };

  const copyCodeHandle = () => {
    navigator.clipboard
      .writeText(sessionStore.agentCode)
      .then(() => {
        toast.success("Code copied successfully!");
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена:", error);
        toast.error("Failed to copy code.");
      });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isItemOptionsOpen, isUserMenuOpen]);

  const handleLogout = useCallback(() => {
    sessionStore.logout().then((res) => {
      if (res === "SUCCESS") {
        window.location.reload();
      }
    });
  }, [sessionStore]);

  useUserEventBusEvents();

  const canBuyFuel = useMemo(() => {
    return (
      sessionStore?.role?.toUpperCase() === "AGENT" ||
      sessionStore?.role?.toUpperCase() === "RETAILOWN"
    );
  }, [sessionStore?.role]);

  const supervisorContact = useMemo(() => {
    return parseTelegramUsername(sessionStore.data.supervisorContact);
  }, [sessionStore.data.supervisorContact]);

  return (
    <div className="header">
      <div className="logo" />
      <div className="headersMenu">
        <NavLink
          id="accountsLink"
          to="/accounts"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          {t("accounts")}
        </NavLink>
        <NavLink
          id="sessionsLink"
          to="/sessions"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          {t("sessions")}
        </NavLink>
        <NavLink
          id="partnersLink"
          to="/partners"
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          {sessionStore.role === "AGENT" ? t("partners") : t("retail")}
        </NavLink>
      </div>
      <div className="extra-menu">
        {isAccountsRouteActive && accountsStore.getSelectedAccounts().length ? (
          <div
            className={`item-options ${isItemOptionsOpen ? "active" : ""}`}
            ref={itemOptionsRef}
          >
            <div className="p-btn" onClick={toggleItemOptions}>
              <SvgOptions />
            </div>
            {isItemOptionsOpen && (
              <div className="dropdown-menu">
                <div
                  onClick={() => accountsStore.updateRestartAccount("update")}
                  className="p-btn item-update"
                >
                  {t("update")}
                </div>
                <div
                  onClick={() => accountsStore.updateRestartAccount("restart")}
                  className="p-btn item-restart"
                >
                  {t("restart")}
                </div>
                <div
                  className="p-btn item-delete"
                  onClick={() => openModal("delete")}
                >
                  {t("delete")}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="item-options-plug" />
        )}
        <div>
          <Select
            options={selectOptions}
            onSelect={handleSelect}
            selected={selectOptions.find(
              (item) => item.value === sessionStore.currentUser
            )}
          />
        </div>
        <div className="p-btn settings" onClick={() => openModal("settings")}>
          <SvgSettings />
        </div>
      </div>
      <div className="balance">
        <div className="balanceSpan">{t("fuelBalance")}</div>
        <span>{headerStore.getCurrentBalance()?.toLocaleString()}</span>
      </div>
      <div className={`manage ${isUserMenuOpen ? "active" : ""}`}>
        <div
          className="p-btn energy"
          onClick={() => {
            if (canBuyFuel) {
              openModal("deposit");
            } else {
              if (supervisorContact) {
                window.open(`https://t.me/${supervisorContact}`, "_blank");
              }
            }
          }}
          css={css`
            &&& {
              opacity: ${!canBuyFuel && !supervisorContact ? 0.3 : 1};
            }
          `}
        >
          <SvgEnergy />
        </div>
        <div className="p-btn history" onClick={() => openModal("history")}>
          <SvgHistory />
        </div>
        <div className={`user ${isUserMenuOpen ? "active" : ""}`} ref={userMenuRef}>
          <div className="p-btn" onClick={toggleUserMenu}>
            <SvgUser />
          </div>
          {isUserMenuOpen && (
            <div className="dropdown-menu">
              <DarkModeSwitch />
              <LangSwitch />
              <a
                href={sessionStore.apkUrl}
                download={extractApkFilename(sessionStore.apkUrl)}
                className="p-btn grayBg"
              >
                APK <SvgApk />
              </a>
              <div className="p-btn grayBg" onClick={() => openModal("setTg")}>
                {t("setTg")}
              </div>
              <div
                className="p-btn grayBg"
                onClick={() => {
                  openGuideModal();
                  setIsUserMenuOpen(false); // Закрытие меню после клика
                }}
              >
                {t("runGuide")}
              </div>
              <div className="p-btn grayBg" onClick={copyCodeHandle}>
                Copy code
              </div>
              <div className="p-btn grayBg" onClick={() => openModal("reset")}>
                Reset code
              </div>
              <div onClick={handleLogout} className="p-btn" id="logout">
                {t("logOut")}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Модальные окна */}
      <Modal
        isOpen={selectedModal === "settings"}
        onRequestClose={closeModal}
        className={`modal ${selectedModal === "settings" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <ConfigModal closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={selectedModal === "reset"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "reset" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <ResetModal onReset={handleGenerateAuthKey} closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={selectedModal === "deposit"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "energy" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <DepositModal closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={selectedModal === "history"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "history" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <HistoryModal closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={selectedModal === "setTg"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "setTg" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <SettgModal closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={selectedModal === "delete"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "delete" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <DeleteModal handleDelete={handleDelete} closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={isGuideModalOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "guide" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay guide-overlay"
      >
        <GuideModal
          closeGuideModal={closeGuideModal}
          openModal={openModal}
          closeModal={closeModal}
          setIsUserMenuOpen={setIsUserMenuOpen}
        />
      </Modal>
    </div>
  );
};

export default Header;

import { createBrowserRouter, redirect } from "react-router-dom";

import { useSessionStore } from "@/entities/user/model/session-store";
import AccountsPage from "@/pages/accounts/accounts-page";
import Login from "@/pages/login/Login";
import PartnersPage from "@/pages/partners/partners-page";
import SessionsPage from "@/pages/sessions/sessions-page";

import { AppLayout } from "./app-layout";

async function protectedLoader() {
  const sessionStore = useSessionStore.getState();
  if (sessionStore.data) {
    return null;
  } else {
    sessionStore.resetUserData();
    return redirect("/login");
  }
}

export const router = createBrowserRouter(
  [
    {
      id: "root",
      path: "/",
      Component: AppLayout,
      children: [
        {
          path: "accounts",
          loader: protectedLoader,
          Component: AccountsPage,
        },
        {
          path: "sessions",
          loader: protectedLoader,
          Component: SessionsPage,
        },
        {
          path: "partners",
          loader: protectedLoader,
          Component: PartnersPage,
        },
        {
          path: "retail",
          loader: protectedLoader,
          Component: PartnersPage,
        },
        {
          path: "/login",
          Component: Login,
          async loader() {
            const userData = useSessionStore.getState().data;
            if (userData?.status === "SUCCESS") {
              return redirect("/accounts");
            } else {
              return null;
            }
          },
          action: async ({ request }) => {
            const formData = await request.formData();
            const agentCode = formData.get("loginInput");

            if (!agentCode) {
              return {
                error: "You must provide a username to log in",
              };
            }

            try {
              await useSessionStore.getState().login(agentCode);
            } catch (error) {
              return {
                error: "Invalid login attempt",
              };
            }

            return redirect("/accounts");
          },
        },
        {
          index: true,
          async loader() {
            return redirect("/accounts");
          },
        },
        {
          path: "*",
          async loader() {
            return redirect("/accounts");
          },
        },
      ],
    },
  ],
  { basename: "/" }
);

import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { useSessionStore } from "@/entities/user/model/session-store";
import { useUserRole } from "@/entities/user/use-user-role";

import { AccountsTableRow } from "./accounts-table-row";

function AccountsPage() {
  const { t } = useTranslation("accounts");
  const { currentUser } = useSessionStore();

  const { selectAllAccounts, fetchAccounts, accounts, selectedAccounts } =
    useAccountsStore();

  const role = useUserRole();
  const isAgentOrSubagent = role === "AGENT" || role === "SUBAGENT";

  useEffect(() => {
    fetchAccounts(currentUser);
  }, [currentUser, fetchAccounts]);

  const isSelectAllChecked = useMemo(() => {
    return (
      selectedAccounts?.length && accounts?.length === selectedAccounts?.length
    );
  }, [accounts?.length, selectedAccounts?.length]);

  const selectAllRows = useCallback(() => {
    selectAllAccounts();
  }, [selectAllAccounts]);

  return (
    <SimpleBar className="content accounts" forceVisible="y" autoHide={false}>
      <div>
        <div className="content-table">
          <div className="table-th">
            <div>#</div>
            <div>
              {/*fixme extract*/}
              <input
                type="checkbox"
                className="select-item"
                onChange={selectAllRows}
                checked={isSelectAllChecked}
              />
            </div>
            <div>{t("accs")}</div>
            <div className="editable-note-th">{t("notes")}</div>
            <div>{t("room")}</div>
            <div>{t("state")}</div>
            <div>{t("type")}</div>
            <div>{t("mode")}</div>
            <div>{t("level")}</div>
            <div>{t("status")}</div>
            <div>{t("hands")}</div>
            <div>{t("win")}</div>
            <div>{t("rake")}</div>
            <div>{t("insur")}</div>
            <div>{t("errs")}</div>
            <div>{t("version")}</div>
            <div>{t("lastGame")}</div>
            {!isAgentOrSubagent && <div>{t("deviceInfo")}</div>}
          </div>
          {accounts?.map((item, index) => {
            return (
              <AccountsTableRow
                key={item?.accId}
                account={item?.account}
                accId={item?.accId}
                deviceInfo={item?.deviceInfo}
                errors={item?.errors}
                hands={item?.hands}
                insur={item?.insur}
                lastGame={item?.lastGame}
                level={item?.level}
                mode={item?.mode}
                notes={item?.notes}
                enabled={item?.enabled}
                rake={item?.rake}
                room={item?.room}
                state={item?.state}
                status={item?.status}
                type={item?.type}
                version={item?.version}
                win={item?.win}
                index={index}
                lastVersion={item?.lastVersion}
              />
            );
          })}
        </div>
        {/*<Modal*/}
        {/*    isOpen={selectedModal === "device"}*/}
        {/*    onRequestClose={closeModal}*/}
        {/*    closeTimeoutMS={300}*/}
        {/*    className={`modal ${selectedModal === "device" ? "closing-effect" : ""}`}*/}
        {/*    overlayClassName='modal-overlay'>*/}
        {/*    <DeviceModal closeModal={closeModal}/>*/}
        {/*</Modal>*/}
      </div>
    </SimpleBar>
  );
}

export default AccountsPage;

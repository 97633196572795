import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";
import SimpleBar from "simplebar-react";

import { usePartnersStore } from "@/entities/partner/model/partners-store";
import {
  usePartnersRetailers,
  usePartnersRetailersQueryKey,
} from "@/entities/partner/model/use-partners-retailers";
import { useSessionStore } from "@/entities/user/model/session-store";
import { useSessionsDefaultFiltersOptions } from "@/entities/user/model/use-sessions-default-filters-options";
import { PartnerActionLink } from "@/pages/partners/partner-action-link";
import { PartnersTableRow } from "@/pages/partners/partners-table-row";
import { api } from "@/shared/constants";
import "@/shared/ui/simplebar/simplebar.css";
import { formatDateShort, smoothScrollToBottom } from "@/shared/utils/utils";
import { useHeaderStore } from "@/store/HeaderStore";

import { PartnersDateEnded } from "./filters/partners-date-ended";
import { PartnersDateStarted } from "./filters/partners-date-started";
import { PartnersPartId } from "./filters/partners-part-id";

function PartnersPage() {
  const { t } = useTranslation("partners");
  const simpleBarRef = useRef(null);
  const sessionStore = useSessionStore();
  const headerStore = useHeaderStore();
  const partnersStore = usePartnersStore();
  const { searchParams, setSearchParams } = partnersStore;

  const [createLoading, setCreateLoading] = useState(false);

  const queryClient = useQueryClient();

  const scrollToBottom = useCallback(() => {
    const scrollElement = simpleBarRef.current.getScrollElement();
    smoothScrollToBottom(scrollElement);
  }, []);

  // fixme extract
  const defaults = useSessionsDefaultFiltersOptions();

  useEffect(() => {
    if (!searchParams?.partIds) {
      setSearchParams({
        // fixme refactor defaults
        partIds: defaults?.partIds?.filter,
        // dateStarted: defaults?.dateStarted?.filter,
        // dateEnded: defaults?.dateEnded?.filter,
      });
      return;
    }
  }, [defaults, searchParams?.partIds, setSearchParams]);

  const currentUser = sessionStore.getCurrentUser();

  const prevCurrentUser = usePrevious(currentUser);
  useEffect(() => {
    if (prevCurrentUser && currentUser !== prevCurrentUser) {
      setSearchParams({
        partIds: currentUser,
      });
    }
  }, [currentUser, prevCurrentUser, searchParams?.partIds, setSearchParams]);

  const partnersRetailers = usePartnersRetailers({
    dateStarted: formatDateShort(searchParams?.dateStarted),
    dateEnded: formatDateShort(searchParams?.dateEnded),
  });

  const loading = partnersRetailers?.isRefetching;

  const addPartner = useCallback(async () => {
    let result;
    setCreateLoading(true);
    if (sessionStore.role === "SUBAGENT") {
      result = await api?.createRetailerReq({
        notes: "",
        partId: sessionStore?.currentUser,
      });
    } else {
      result = await api.createPartnerReq({
        notes: "",
        contact: sessionStore.data.contact,
      });
    }
    if (result?.data?.status?.toUpperCase() === "SUCCESS") {
      sessionStore.getSubpartnersReq();
      await queryClient?.invalidateQueries({
        queryKey: [usePartnersRetailersQueryKey],
      });
      setTimeout(() => {
        scrollToBottom();
      }, 10);
    } else {
      console.log("xxxxxxxxxxxx errrrrrrrrrrrrrrr ", result);
    }
    setCreateLoading(false);
  }, [queryClient, scrollToBottom, sessionStore]);

  return (
    <SimpleBar
      ref={simpleBarRef}
      className="content partners"
      forceVisible="y"
      autoHide={false}
    >
      <div style={{ position: "relative" }}>
        <div className="table-filters">
          <div className="table-filters-l">
            <PartnersPartId name="partIds" placeholder="Partner" disabled />
            <PartnersDateStarted disabled={loading} />
            <PartnersDateEnded disabled={loading} />
          </div>
          <div className="table-filters-r">
            <PartnerActionLink disabled={loading} onClick={addPartner}>
              {sessionStore?.role === "AGENT" ? t("addPartner") : t("addSlot")}
            </PartnerActionLink>
          </div>
        </div>
        {/* TODO обработать начальное состояние и случай если по запросу нет данных */}
        <div className="content-table">
          <div className="table-th">
            <div>#</div>
            <div>{t("partner")}</div>
            <div>{t("notes")}</div>
            {sessionStore.role === "AGENT" && <div>{t("accs")}</div>}
            <div>{t("fuelBilled")}</div>
            <div>{t("hands")}</div>
            <div>{t("win")}</div>
            <div>{t("rake")}</div>
            <div>{t("insur")}</div>
            <div>{t("errs")}</div>
            <div>{t("fuelBalance")}</div>
            <div className="fuel-operations">{t("fuelOperations")}</div>
            <div className="partner-history">{t("history")}</div>
          </div>
          {partnersRetailers?.data?.length
            ? partnersRetailers?.data?.map((item, index) => (
                <PartnersTableRow
                  isLoading={loading}
                  key={item.partId}
                  index={index}
                  partnerName={item.partnerName}
                  notes={item.notes}
                  accs={item.totalAccounts}
                  fuelBilled={item.fuelBilled}
                  hands={item.totalHands}
                  win={item.totalWinLose}
                  rake={item.totalRake}
                  insur={item.totalInsurance}
                  errors={item.totalErrors}
                  fuelBalance={item.totalFuel}
                  authKey={item.authKey}
                  partnerId={item.partId}
                />
              ))
            : ""}
        </div>
      </div>
    </SimpleBar>
  );
}

export default PartnersPage;

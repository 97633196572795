import { Api } from "@/api/Api";

const _api = new Api();
export const { api, instance: apiInstance } = _api;

export let sessionStore = { current: null };

// fixme use back
export const pokerApps = [
  {
    name: "HHPoker",
    packageName: "com.illuminate.hhpoker",
  },
  {
    name: "PPPoker",
    packageName: "com.lein.pppoker.android",
  },
  {
    name: "CityOfPoker",
    packageName: "com.phpoker.life.AAPOKER123",
  },
  {
    name: "PokerTime",
    packageName: "com.block.poker",
  },
  {
    name: "PokerBros",
    packageName: "com.kpgame.PokerBros",
  },
  {
    name: "WePoker",
    packageName: "com.baoxue.bolizha",
  },
  {
    name: "WePokerClub",
    packageName: "com.baoxue.bolizha",
  },
  {
    name: "Game",
    packageName: "com.ayyl.pksj",
  },
  {
    name: "Xpoker",
    packageName: "com.game.android.xpoker",
  },
  {
    name: "KKPoker",
    packageName: "com.lein.pppokerpro.android",
  },
  {
    name: "Suprema",
    packageName: "com.opt.supremapoker",
  },
  {
    name: "Pokerrrr2",
    packageName: "com.ptfarm.pokerrrr",
  },
  {
    name: "ClubGG",
    packageName: "com.nsus.clubgg",
  },
  {
    name: "PokerKing",
    packageName: "com.pokersgame.pkw",
  },
  {
    name: "GGPoker",
    packageName: "com.nsus.poker.prodggpcom",
  },
  {
    name: "Poker2U",
    packageName: "net.poker2u.game",
  },
  {
    name: "QQPoker",
    packageName: "com.RHSOFT.HoldemQ",
  },
  {
    name: "IdnPoker",
    packageName: "com.idn.txh",
  },
  {
    name: "DPZX",
    packageName: "com.dpzx.pro",
  },
  {
    name: "HeroPoker",
    packageName: "com.symlgame.pokerhero",
  },
  {
    name: "PokerOK",
    packageName: "com.nsus.poker.prodpokerok",
  },
  {
    name: "Natural8",
    packageName: "com.nsus.poker.prodnatural8",
  },
  {
    name: "7XL",
    packageName: "com.nsus.poker.prod7xl",
  },
  {
    name: "EPoker",
    packageName: "abcd.easypoker.com",
  },
];
